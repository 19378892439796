<template lang="pug">
    .bdi-layouts
        GeneralSidebar
        .bdi-layouts__content
            HeaderPage(title="Laporan")
            routerView
</template>

<script>
import GeneralSidebar from '@/pages/general/Sidebar';
import HeaderPage from '@/components/sections/HeaderPage';
export default {
    name: 'ReportPageShell',
    components: {
        GeneralSidebar,
        HeaderPage,
    },
    data() {
        return {
            TwitterHidden: true,
        };
    },
    methods: {
        // 
    },
    created() {
        // 
    },
};
</script>
